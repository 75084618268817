import React, { Component } from 'react';
import Webcam from "react-webcam";

class WebcamCapture extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            videoConstraints: {
                facingMode: "user",
                aspectRatio: 300 / 420
            },
            height: 420,
            width: 300,
            imgState: "Not Load",
            imgSrc: null,
            refresh: false,
        }

        this.img = null;
        this.loadingProc = this.loadingProc.bind(this);
    }

    componentDidMount() {
        
    }

    loadingProc() {
        const __this = this;
        const screenshot = __this.webcam.getScreenshot();

        let img = new Image();

        img.addEventListener("load", function () {
            const imgWidth = this.naturalWidth;
            const imgHeight = this.naturalHeight;

            if (__this.props.parent.state.camera_for === 'FotoDiri') {
                if (imgWidth > imgHeight) {
                    __this.setState({
                        imgState: "Loaded...",
                        width: imgWidth,
                        height: imgHeight,
                        loading: false,
                        videoConstraints: {
                            facingMode: "user",
                            aspectRatio: 420 / 300
                        }
                    });
                } else {
                    __this.setState({
                        imgState: "Loaded...",
                        width: imgWidth,
                        height: imgHeight,
                        loading: false,
                        videoConstraints: {
                            facingMode: "user",
                            aspectRatio: 300 / 420
                        }
                    });
                }
            } else {
                if (imgWidth > imgHeight) {
                    __this.setState({
                        imgState: "Loaded...",
                        width: imgWidth,
                        height: imgHeight,
                        loading: false,
                        videoConstraints: {
                            facingMode: "user",
                            aspectRatio: 300 / 420
                        }
                    });
                } else {
                    __this.setState({
                        imgState: "Loaded...",
                        width: imgWidth,
                        height: imgHeight,
                        loading: false,
                        videoConstraints: {
                            facingMode: "user",
                            aspectRatio: 420 / 300
                        }
                    });
                }
            }


        });
        img.src = screenshot;
    }

    capture() {
        const screenshot = this.webcam.getScreenshot();

        this.props.parent.captureFromCamera(screenshot);
    }

    batal() {
        this.props.parent.setState({ page: 1, is_camera_active: false, })
    }

    render() {
        return (
            <div>
                <Webcam
                    audio={false}
                    // height={this.state.height}
                    ref={node => this.webcam = node}
                    screenshotFormat="image/jpeg"
                    width={400}
                    videoConstraints={this.state.videoConstraints}
                    onUserMedia={this.loadingProc}
                />

                {
                    (false)
                        ?
                        <div style={{ paddingTop: 16 }}>
                            <p>Loading Camera...</p>
                            <button name="process" className="tombol" onClick={this.batal.bind(this)}>Batal</button>
                        </div>
                        :
                        <div style={{ paddingTop: 16 }}>
                            <button name="process" className="tombol" onClick={this.capture.bind(this)}>Ambil Foto</button>&nbsp;
                            <button name="process" className="tombol" onClick={this.batal.bind(this)}>Batal</button>
                        </div>
                }
            </div>
        )
    }
}

export default WebcamCapture;